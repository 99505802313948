// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  login: `Login`,
  logout: `Logout`,
  my_profile: `My Profile`,
  basic_information: `Basic Information`,
  edit: `Edit`,
  user_id: `User ID`,
  username: `Username`,
  account_type: `Account Type`,
  status: `Status`,
  currency: `Currency`,
  created_time: `Created Time`,
  last_login: `Last Login`,
  prefix: `Prefix`,
  cert: `Cert`,
  wallet_type: `Wallet Type`,
  product_information: `Product Information`,
  platform: `Platform`,
  supported_currency: `Supported Currency`,
  my_pt_allow: `My Pt Allow`,
  bet_limit: `Bet Limit`,
  fish: `Fish`,
  live: `Live`,
  slot: `Slot`,
  table: `Table`,
  virtual: `Virtual`,
  member_tree: `Member Tree`,
  real: `Real`,
  test: `Test`,
  active: `Active`,
  suspended: `Suspended`,
  locked: `Locked`,
  submit: `Submit`,
  export: `Export`,
  view: `View`,
  login_failure: `Login Failure`,
  password_lock: `Password Lock`,
  input_x: `Input {{x}}`,
  sub_account: `Sub Account`,
  add_sub_account: `Add Sub Account`,
  win_loss_summary: `Win / Loss Summary`,
  date_time: `Date and Time`,
  time_zone: `Time Zone`,
  type: `Type`,
  location: `Location`,
  last_month: `Last Month`,
  this_month: `This Month`,
  last_week: `Last Week`,
  this_week: `This Week`,
  yesterday: `Yesterday`,
  today: `Today`,
  view_all: `View All`,
  display_columns: `Display Columns`,
  player_summary: `Player Summary`,
  valid_turnover: `Valid Turnover`,
  total_bet: `Total Bet`,
  bet_amount: `Bet Amount`,
  player: `Player`,
  player_win_loss: `Player W/L`,
  adjustment: `Adjustment`,
  total_pl: `Total P/L`,
  margin: `Margin`,
  pt_win_loss: `PT Win/Loss`,
  agent: `Agent`,
  company: `Company`,
  grand_total_x: `Grand Total ({{x}})`,
  start_date: `Start Date`,
  end_date: `End Date`,
  clear: `Clear`,
  bet_count: `Bet Count`,
  total: `Total`,
  all: `All`,
  adjustment_detail: `Adjustment Detail`,
  adjustment_type: `Adjustment Type`,
  game_type: `Game Type`,
  adjustment_types: `Adjustment Types`,
  items: `Items`,
  account_statement: `Account Statement`,
  player_id: `Player ID`,
  debit: `Debit`,
  credit: `Credit`,
  before_balance: `Before Balance`,
  after_balance: `After Balance`,
  request_id: `Request ID`,
  tip_report: `Tip Report`,
  no_data: `No Data`,
  total_tip: `Total Tip`,
  products: `Products`,
  push_message: `Push Message`,
  add_product: `Add Product`,
  send_notification: `Send Notification`,
  product_name: 'Product Name',
  search: 'Search',
  is_active: 'Is Active',
  permission: 'Permission',
  currency_list: 'Currency List',
  add_currency: 'Add Currency',
  edit_currency: 'Edit Currency',
  venue_list: 'Venue List',
  add_venue: 'Add Venue',
  edit_venue: 'Edit Venue',
  tier_list: 'Tier List',
  add_tier: 'Add Tier',
  edit_tier: 'Edit Tier',
  player_performance_list: 'Player Performance List',
  groups_list: 'Groups List',
  group_qr: 'Group QR',
  edit_group: 'Edit Group',
  group_details: 'Group Details',
  sub_agent_list: 'Sub Agent List',
  add_sub_agent: 'Add Sub Agent',
  member_list: 'Members List',
  add_member: 'Add Member',
  edit_member: 'Edit Member',
  full_name: 'Full Name',
  phone_number: 'Phone Number',
  change_password: 'Change Password',
  current_password: 'Current Password',
  new_password: 'New Password',
  retype_password: 'Re-type Password',
  reset: 'Reset',
  allocate_chips: 'Allocate Chips',
  approved_allocated_chips: 'Approved Allocated Chips',
  flight_and_accomodation: 'Flight & Accomodation',
  win_loss_chips: 'Win/Loss Chips',
  x_must_be_y_characters: `{{x}} must be at least {{y}} characters`,
  password_doesnt_match: `Password doesn't match`,
  agents: 'Agents',
  add_agent: 'Add Agent',
  agents_list: 'Agents List',
  groups: `Groups`,
  name: `Name`,
  venue: `Venue`,
  group_id: `Group ID`,
  yes: `Yes`,
  no: `No`,
  create_group: `Create Group`,
  group: `Group`,
  add: `Add`,
  edit_flight_total_cost: `Edit Flight Total Cost`,
  edit_accommodation_total_cost: `Edit Accommodation Total Cost`,
  id: `ID`,
  flight_cost: `Flight Cost`,
  accommodation_cost: `Accommodation Cost`,
  allocated_chips: `Allocated Chips`,
  created_at: `Created at`,
  created_by: `Created by`,
  updated_at: `Updated at`,
  updated_by: `Updated by`,
  optional: `Optional`,
  main_agent: `Main Agent`,
  commission: `Commission`,
  enter_x: `Enter {{x}}`,
  back: `Back`,
  skip: `Skip`,
  next: `Next`,
  group_n_agent: `Group & Agent`,
  venue_detail: `Venue Detail`,
  itenerary_cost: `Itenerary Cost`,
  chips_entry: `Chips Entry`,
  x_is_required: `{{x}} is required`,
  action: `Action`,
  flight: `Flight`,
  accommodation: `Accommodation`,
  company_win_loss: `Company W/L`,
  insurance: `Insurance`,
  total_flight_cost: `Total Flight Cost`,
  total_accommodation_cost: `Total Accommodation Cost`,
  total_member_win_loss: `Total Member W/L`,
  total_agent_commission: `Total Agent Commission`,
  total_group_adjustment: `Total Group Adjustment`,
  total_company_win_loss: `Total Company W/L`,
  total_member_insurance: `Total Member Insurance`,
  adjustment_success: `Adjustment Success!`,
  generic_error_message: `Something Went wrong, please contact support.`,
  debit_adjustment: `Debit Adjustment`,
  credit_adjustment: `Credit Adjustment`,
  remarks: `Remarks`,
  amount: `Amount`,
  win_chips: `Win Chips`,
  lose_chips: `Lose Chips`,
  buy_insurance: `Buy Insurance`,
  is_ended: `Is Ended?`,
  transactions_list: `Transactions List`,
  users: 'Users',
  inactive: 'Inactive',
  add_user: 'Add User',
  edit_user: 'Edit User',
  at_least_8: 'At least 8 characters',
  min_char_is_x: 'Minimum character is {{x}}',
  invalid_input: 'Invalid Input',
  user_added: 'User Added',
  user_edited: 'User Edited',
  group_edited: 'Group Edited',
  group_added: 'Group Added',
  currency_code: `Currency Code`,
  symbol: `Symbol`,
  currency_added: `Currency Added`,
  currency_editted: `Currency Editted`,
  cancel: `Cancel`,
  agent_id: `Agent ID`,
  agent_name: `Agent Name`,
  password: `Password`,
  venue_name: `Venue Name`,
  country: `Country`,
  target_point: `Target Point`,
  venue_id: `Venue ID`,
  show_qr: `Show QR`,
  group_information: `Group Information`,
  enlarge_qr: `Enlarge QR`,
  select_agent: `Select Agent`,
  is_main: `Is Main`,
  flight_and_accommodation_cost: `Flight and Accommodation Cost`,
  flight_total_cost: `Flight Total Cost`,
  accommodation_total_cost: `Accommodation Total Cost`,
  member_information: `Member Information`,
  win_lose: `Win/Lose`,
  over_all_win_lose_amount: `Overall Win/Lose Amount`,
  insurance_amount: `Insurance Amount`,
  flight_accommodation: `Flight/Accommodation`,
  transactions: `Transactions`,
  member_username: `Member Username`,
  member_name: `Member Name`,
  title: `Title`,
  created_date: `Created Date`,
  total_commission_amount: `Total Commission Amount`,
  agent_commission: 'Agent Commission',
  last_login_at: `Last Login At`,
  role: `Role`,
  turnover_amount: `Turnover Amount`,
  tier: `Tier`,
  lifetime_points: `Lifetime Points`,
  current_point: `Current Points`,
  member_profile: `Member Profile`,
  stats_by_currency: `Stats By Currency`,
  overall_win_lose_amount: `Overall Win/Lose Amount`,
  overall_turnover_amount: `Overall Turnover Amount`,
  flight_info: `Flight Info`,
  accommodation_info: `Accommodation Info`,
  win_lose_amount: `Win/Lose Amount`,
  sort_by: `Sort By`,
  winlose_desc: `Win/Lose Descending`,
  winlose_asc: `Win/Lose Ascending`,
  turnover_desc: `Turnover Descending`,
  turnover_asc: `Turnover Ascending`,
  add_turnover: `Add Turnover`,
  turnover: `Turnover`,
  view_member: `View Member`,
  dashboard: `Dashboard`,
  enter_amount: `Enter Amount`,
  please_select_country: `Please Select Country`,
  scan_qr_warning: `Scan the QR code to become a part of the Group`,
  admin: `Admin`,
  report: `Report`,
  tiers: `Tiers`,
  player_performance: `Player Performance`,
  home: `Home`,
  profile: `Profile`,
  updated_date: `Updated Date`,
  please_select_x: `Please select {{x}}`,
  tier_name: 'Tier Name',
  page: `Page`,
  group_performance: `Group Performance`,
  report_agent: `Report`,
  group_name: `Group Name`,
  agent_username: 'Agent Username',
  group_performance_list: `Group Performance List`,
  edit_agent: `Edit Agent`,
  // translate('please_select_x', { x: translate('payment_method') })
  currency_rate_to_myr: `Currency Rate (To MYR)`,
  position_taking: `Position Taking`,
  runner: `Runner`,
  agent_pt: `Agent PT (%)`,
  rebate: `Rebate`,
  chips: `Chips`,
  all_player_default_credit_limit: `All Player's default credit limit`,
  casino_pt: `Casino PT (%)`,
  top1_pt: `Top1 PT (%)`,
  casino_commission: `Casino Commission`,
  root_top1_commission: `Top1 Commission`,
  pt: 'PT (%)',
  casino: `Casino`,
  add_runner: `Add Runner`,
  total_chips: 'Total Chips',
  join_date_from: 'Join Date From',
  join_date_to: 'Join Date To',
  win: 'Win',
  lose: 'Lose',
  member_phonenumber: 'Member Phone Number',
  transaction_type: 'Transaction Type',
  date_from: 'Date From',
  date_to: 'Date To',
  belongs_to_agent: 'Belongs to Agent',
  action_by: 'Action By',
  credit_limit: 'Credit Limit',
  join_date: 'Join Date',
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  rebate_amount: 'Rebate Amount',
  final_win_lose: 'Final Win Lose',
  assign_chips: 'Assign Chips',
  set_turnover_amount: 'Set Turnover Amount',
  return_chips: 'Return Chips',
  set_rebate_perc: 'Set Rebate %',
  set_credit_limit: 'Set Credit Limit',
  set_insurance_amount: 'Set Insurance Amount',
  agent_id_must_be_unique: `Duplicate Agent found`,
  runner_id_must_be_unique: `Duplicate Runner found`,
  subadmin: `Sub Admin`,
  under_agent: 'Under Agent',
  expenses: `Expenses`,
  agent_qr: `Agent QR`,
  generate_qr: `Generate QR`,
  total_agents: 'Total Agents',
  total_players: 'Total Players',
  total_player_win_lose: 'Total Player W/L',
  total_player_turnover: 'Total Player Turnover',
  total_player_rebate_amount: 'Total Player Rebate Amount',
  total_player_final_win_lose: 'Total Player Final W/L',
  top_1_total_pay: 'Top1 Total Pay',
  agents_total_pay: 'Agents Total Pay',
  total_expenses: 'Total Expenses',
  total_groups: 'Total Groups',
  top1_pay: 'Top1 Pay',
  agent_pay: 'Agent Pay',
  group_start_date_from: 'Group Start Date From',
  group_start_date_to: 'Group Start Date To',
  agent_performance: 'Agent Performance',
  list_of_expenses: `List of Expenses`,
  add_expense: `Add Expense`,
  select_group: `Select Group`,
  member: `Member`,
  remark: `Remark`,
  max_is_x: `Max is {{x}}`,
  min_is_x: `Min is {{x}}`,
  expense_is_for: `Expense is for`,
  for_group: `For Group`,
  for_agent: `For Agent`,
  for_member: `For Member`,
  select_member: `Select Member`,
  no_member_found: `No Member found`,
  no_agent_found: `No Agent found`,
  total_win_lose: 'Total Win Lose',
  total_turnover: 'Total Turnover',
  total_rebate_amount: 'Total Rebate Amount',
  player_final_win_lose: 'Player Final W/L',
  can_not_be_more_than_top1: `Can not be more that Top 1 PT`,
  group_total_chips: `Group Total Chips`,
  please_select_user_type: 'Please select your user type',
  select_type: 'Select Type',
  login_as_x: 'Login as {{x}}',
  member_phone_number: `Member Phone Number`,
  create_date_from: `Create Date From`,
  create_date_to: `Create Date To`,
  qr_code: 'QR Code',
  finalize: `Finalize`,
  confirm_finalize: `Are you sure you want to Finalize this Group?`,
  proceed: `Proceed`,
  success: `Success!`,
  can_allocate_chip: `Can Allocate Chip`,
  can_return_chip: `Can Return Chip`,
  credit_limit_balance: `Credit Limit Balance`,
  overall_final_wl_amount: `Overall Final W/L Amount`,
  overall_rebate_amount: `Overall Rebate Amount`,
  group_agent_name: `Group/Agent Name`,
  has_pending_chips: `Has Pending Chips`,
  final_wl_amount: `Final W/L Amount`,
  group_stats: 'Group Stats',
  chips_allocated: 'Chips Allocated',
  player_win_lose: 'Player W/L',
  chips_returned: 'Chips Returned',
  last_24_hours: 'Last 24 hours',
  accomodation_info: 'Accomodation Info',
  remove_member: 'Remove Member',
  confirm_remove_member: 'Are you sure you want to Remove Member?',
  confirm_password: 'Confirm Password',
  password_not_match: 'Password not match',
  // TODO: Translate to other lang
};

export default en;
