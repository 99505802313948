import { useMemo, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  ButtonBase,
  Card,
  Stack,
  Link,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
} from '@mui/material';
// hooks
import { AnimatePresence, m } from 'framer-motion';
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
// sections
import { LoginForm } from '../../sections/auth/login';
import { USERTYPES } from '../../config';
import { varSlide } from '../../components/animate';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  // zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '70vh',
  display: 'flex',
  // justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const { translate } = useLocales();

  const [loginType, setLoginType] = useState('admin');

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const loginTypeData = useMemo(() => {
    if (loginType) {
      const userType = USERTYPES.find((x) => x.slug === loginType);

      return userType || {};
    }

    return {};
  }, [loginType]);

  const handleChangeType = (e) => {
    setLoginType(e.target.value);
  };

  return (
    <Page title="Login">
      <RootStyle>
        <img
          src={require('../../assets/casino_bg.jpg')}
          alt="Login BG"
          style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, width: '100%', height: '100%' }}
        />
        <HeaderStyle>
          <Logo />
          {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        <Card
          component={Container}
          maxWidth="sm"
          sx={{ background: 'rgb(33, 43, 54)', borderRadius: '5px', width: { md: '100%', sm: '80%', xs: '80%' } }}
        >
          <ContentStyle>
            {/* {loginType ? ( */}
            <>
              <Typography variant="h3" sx={{ textAlign: 'center', mb: 2, color: '#fff' }}>
                {translate('login')}
              </Typography>
              {Boolean(!loginType) && (
                <Typography sx={{ mb: 1, color: '#fff' }}>{translate('please_select_user_type')}</Typography>
              )}
              {/* <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Select Type</InputLabel> */}
              <TextField
                select
                // SelectProps={{ native: true }}
                onChange={handleChangeType}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: 'rgb(33, 43, 54)',
                        color: '#fff',
                        boxShadow: 'none',
                        border: '1px solid rgba(255,255,255,0.1)',
                      },
                    },
                  },
                }}
                value={loginType}
                sx={{
                  mb: 4,
                  color: 'white',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#fff',
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    color: '#fff !important',
                  },
                }}
              >
                {/* <option value="" /> */}
                {USERTYPES.map((u) => (
                  <MenuItem value={u.slug} key={u.slug}>
                    {u.name}
                  </MenuItem>
                ))}
              </TextField>
              {/* </FormControl> */}

              {loginType && (
                <AnimatePresence>
                  <Box component={m.div} {...varSlide().inLeft}>
                    <Typography sx={{ mb: 1, color: '#fff' }}>
                      {translate('login_as_x', { x: loginTypeData.name })}
                    </Typography>
                    <LoginForm loginType={loginTypeData.type} />
                  </Box>
                </AnimatePresence>
              )}
            </>
            {/* ) : (
              <>
                <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', color: '#fff' }}>
                  Login as?
                </Typography>
                <Stack spacing={2} alignItems={'center'} justifyContent={'center'} sx={{ mt: 2 }}>
                  <ButtonBase
                    sx={{
                      color: '#fff',
                      p: 2,
                      width: '150px',
                      borderRadius: '5px',
                      background: 'green',
                      fontSize: '15px',
                      textShadow: '-1px 1px 0px black',
                      fontWeight: 700,
                    }}
                    onClick={() => setLoginType('sa')}
                  >
                    SUPER ADMIN
                  </ButtonBase>
                  <ButtonBase
                    sx={{
                      color: '#fff',
                      p: 2,
                      width: '150px',
                      borderRadius: '5px',
                      background: 'orange',
                      fontSize: '15px',
                      textShadow: '-1px 1px 0px black',
                      fontWeight: 700,
                    }}
                    onClick={() => setLoginType('agent')}
                  >
                    AGENT
                  </ButtonBase>
                </Stack>
              </>
            )} */}
          </ContentStyle>
        </Card>
      </RootStyle>
    </Page>
  );
}
