// routes
import { PATH_SUB_ACCOUNT, PATH_REPORTS, PATH_ADMIN, PATH_GROUPS, PATH_EXPENSES } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const subMenuIcon = (ic) => <Iconify icon={ic} />;

const navConfig = [
  {
    subheader: '',
    items: [
      // REPORTS
      // ----------------------------------------------------------------------
      {
        title: 'report',
        path: PATH_REPORTS.root,
        icon: subMenuIcon('iconoir:reports-solid'),
        selected: true,
        children: [
          {
            title: 'group_performance',
            path: PATH_REPORTS.groupPerformance,
          },
          {
            title: 'player_performance',
            path: PATH_REPORTS.playerPerformance,
          },
          {
            title: 'agent_performance',
            path: PATH_REPORTS.agentPerformance,
          },
        ],
        perms: ['subadmin', 'admin', 'agent'],
      },
      // AGENT DASHBOARD
      // ----------------------------------------------------------------------
      // {
      //   title: 'report_agent',
      //   path: PATH_REPORTS.root,
      //   icon: subMenuIcon('iconoir:reports-solid'),
      //   children: [
      //     {
      //       title: 'group_performance',
      //       path: PATH_DASHBOARD.agentDashboard,
      //     },
      //     {
      //       title: 'player_performance',
      //       path: PATH_REPORTS.playerPerformance,
      //     },
      //     {
      //       title: 'agent_commission',
      //       path: PATH_REPORTS.agentPerformance,
      //     },
      //   ],
      // },

      // ADMIN
      // ----------------------------------------------------------------------
      {
        title: 'admin',
        path: PATH_ADMIN.root,
        icon: subMenuIcon('ic:round-manage-accounts'),
        children: [
          {
            title: 'agents',
            path: PATH_ADMIN.agents,
          },
          {
            title: 'currency',
            path: PATH_ADMIN.currency,
          },
          {
            title: 'venue',
            path: PATH_ADMIN.venue,
          },
          {
            title: 'groups',
            path: PATH_ADMIN.groups,
          },
          {
            title: 'users',
            path: PATH_ADMIN.users,
            perms: ['admin'],
          },
          {
            title: 'tiers',
            path: PATH_ADMIN.tiers,
          },
        ],
        perms: ['admin'],
      },

      // GROUPS
      // ----------------------------------------------------------------------
      {
        title: 'groups',
        path: PATH_GROUPS.root,
        icon: subMenuIcon('mingcute:group-3-fill'),
      },

      // EXPENSES
      // ----------------------------------------------------------------------
      {
        title: 'expenses',
        path: PATH_EXPENSES.root,
        icon: subMenuIcon('vaadin:money-withdraw'),
        perms: ['admin'],
      },

      // SUB ACCOUNT
      // ----------------------------------------------------------------------
      // {
      //   title: 'sub account',
      //   path: PATH_SUB_ACCOUNT.root,
      //   icon: subMenuIcon('mdi:account-group'),
      // },
    ],
  },
];

export default navConfig;
